<template>
  <div class="app-container">
    <h3 style="text-align: center">
      <strong><span data-contrast="auto">プライバシーポリシー</span></strong><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </h3>
    <p>
      <span data-contrast="auto">株式会社</span><span data-contrast="auto">H.S.P</span><span
        data-contrast="auto"
      >（以下，「当社」といいます。）は，本アプリ上で提供するサービス（以下</span><span data-contrast="auto">,</span><span
        data-contrast="auto"
      >「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong><span data-contrast="auto">第</span></strong><strong><span data-contrast="auto">1</span></strong><strong><span data-contrast="auto">条（個人情報）</span></strong><span
        data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:40}"
      >&nbsp;</span>
    </p>
    <p>
      <span
        data-contrast="auto"
      >「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong><span data-contrast="auto">第</span></strong><strong><span data-contrast="auto">2</span></strong><strong><span data-contrast="auto">条（個人情報の収集方法）</span></strong><span
        data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:40}"
      >&nbsp;</span>
    </p>
    <p>
      <span
        data-contrast="auto"
      >当社は，ユーザーが利用登録をする際に氏名，電話番号，メールアドレスなどの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を</span><span data-contrast="auto">,</span><span
        data-contrast="auto"
      >当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong><span data-contrast="auto">第</span></strong><strong><span data-contrast="auto">3</span></strong><strong><span
        data-contrast="auto"
      >条（個人情報を収集・利用する目的）</span></strong><span
        data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:40}"
      >&nbsp;</span>
    </p>
    <p>
      <span
        data-contrast="auto"
      >当社が個人情報を収集・利用する目的は，以下のとおりです。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">1.</span><span data-contrast="auto">当社サービスの提供・運営のため</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">2.</span><span
        data-contrast="auto"
      >ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">3.</span><span
        data-contrast="auto"
      >ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">4.</span><span
        data-contrast="auto"
      >メンテナンス，重要なお知らせなど必要に応じたご連絡のため</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">5.</span><span
        data-contrast="auto"
      >利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">6.</span><span
        data-contrast="auto"
      >ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">7.</span><span data-contrast="auto">上記の利用目的に付随する目的</span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong><span data-contrast="auto">第</span></strong><strong><span data-contrast="auto">4</span></strong><strong><span data-contrast="auto">条（利用目的の変更）</span></strong><span
        data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:40}"
      >&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">1.</span><span
        data-contrast="auto"
      >当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">2.</span><span
        data-contrast="auto"
      >利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong><span data-contrast="auto">第</span></strong><strong><span data-contrast="auto">5</span></strong><strong><span data-contrast="auto">条（個人情報の第三者提供）</span></strong><span
        data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:40}"
      >&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">①</span><span
        data-contrast="auto"
      >当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">1.</span><span
        data-contrast="auto"
      >人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">2.</span><span
        data-contrast="auto"
      >公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">3.</span><span
        data-contrast="auto"
      >国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span></p>
    <p>
      <span data-contrast="auto">4.</span><span
        data-contrast="auto"
      >予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">1.</span><span data-contrast="auto">利用目的に第三者への提供を含むこと</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">2.</span><span data-contrast="auto">第三者に提供されるデータの項目</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">3.</span><span data-contrast="auto">第三者への提供の手段または方法</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">4.</span><span
        data-contrast="auto"
      >本人の求めに応じて個人情報の第三者への提供を停止すること</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">5.</span><span data-contrast="auto">本人の求めを受け付ける方法</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span></p>
    <p>
      <span data-contrast="auto">②</span><span
        data-contrast="auto"
      >前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">1.</span><span
        data-contrast="auto"
      >当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">2.</span><span
        data-contrast="auto"
      >合併その他の事由による事業の承継に伴って個人情報が提供される場合</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">3.</span><span
        data-contrast="auto"
      >個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span></p>
    <p>
      <strong><span data-contrast="auto">第</span></strong><strong><span data-contrast="auto">6</span></strong><strong><span data-contrast="auto">条（個人情報の開示）</span></strong><span
        data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:40}"
      >&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">①</span><span
        data-contrast="auto"
      >当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">1.</span><span
        data-contrast="auto"
      >本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">2.</span><span
        data-contrast="auto"
      >当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">3.</span><span data-contrast="auto">その他法令に違反することとなる場合</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span></p>
    <p>
      <span data-contrast="auto">②</span><span
        data-contrast="auto"
      >前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span></p>
    <p>
      <strong><span data-contrast="auto">第</span></strong><strong><span data-contrast="auto">7</span></strong><strong><span
        data-contrast="auto"
      >条（個人情報の訂正および削除）</span></strong><span
        data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:40}"
      >&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">1.</span><span
        data-contrast="auto"
      >ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">2.</span><span
        data-contrast="auto"
      >当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">3.</span><span
        data-contrast="auto"
      >当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span></p>
    <p>
      <strong><span data-contrast="auto">第</span></strong><strong><span data-contrast="auto">8</span></strong><strong><span data-contrast="auto">条（個人情報の利用停止等）</span></strong><span
        data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:40}"
      >&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">1.</span><span
        data-contrast="auto"
      >当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">2.</span><span
        data-contrast="auto"
      >前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">3.</span><span
        data-contrast="auto"
      >当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">4.</span><span data-contrast="auto">前</span><span data-contrast="auto">2</span><span
        data-contrast="auto"
      >項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span></p>
    <p>
      <strong><span data-contrast="auto">第</span></strong><strong><span data-contrast="auto">9</span></strong><strong><span
        data-contrast="auto"
      >条（プライバシーポリシーの変更）</span></strong><span
        data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:40}"
      >&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">1.</span><span
        data-contrast="auto"
      >本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">2.</span><span
        data-contrast="auto"
      >当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span></p>
    <p>
      <strong><span data-contrast="auto">第</span></strong><strong><span data-contrast="auto">10</span></strong><strong><span data-contrast="auto">条（お問い合わせ窓口）</span></strong><span
        data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:40}"
      >&nbsp;</span>
    </p>
    <p>
      <span
        data-contrast="auto"
      >本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">住所：</span><span
        data-contrast="none"
      ><span
        data-ccp-charstyle="s"
        data-ccp-charstyle-defn="{&quot;ObjectId&quot;:&quot;d395ce7f-eca5-4ae0-88ea-9cc6e1b0cb06|137&quot;,&quot;ClassId&quot;:1073872969,&quot;Properties&quot;:[134233614,&quot;true&quot;,201340122,&quot;1&quot;,469775450,&quot;s&quot;,469778129,&quot;s&quot;,469778324,&quot;Default Paragraph Font&quot;]}"
      >〒</span></span><span
        data-contrast="none"
      ><span data-ccp-charstyle="s">107</span><span data-ccp-charstyle="s">-00</span><span data-ccp-charstyle="s">51</span><span data-ccp-charstyle="s">&nbsp;</span></span><span
        data-contrast="none"
      ><span data-ccp-charstyle="s">東京都港区元赤坂</span></span><span
        data-contrast="none"
      ><span data-ccp-charstyle="s">1-5-11&nbsp;</span></span><span
        data-contrast="none"
      ><span data-ccp-charstyle="s">元赤坂マンション</span></span><span data-contrast="none"><span data-ccp-charstyle="s">601</span></span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">社名：株式会社</span><span data-contrast="auto">H.S.P</span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">担当部署：</span><span
        data-contrast="none"
      ><span data-ccp-charstyle="s">問い合わせ窓口</span></span><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">E</span><span data-contrast="auto">メールアドレス：</span><a
        href="mailto:info@hapisuku.com"
      ><span
        data-contrast="none"
      ><span data-ccp-charstyle="Hyperlink">info@hapisuku.com</span></span></a><span data-ccp-props="{&quot;335551550&quot;:1,&quot;335551620&quot;:1}">&nbsp;</span>
    </p>
    <p>
      <span data-contrast="auto">以上</span><span data-ccp-props="{&quot;335551550&quot;:3,&quot;335551620&quot;:3}">&nbsp;</span>
    </p>
    <div id="gtx-trans" style="position: absolute; left: 117px; top: -15px">
      &nbsp;
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy'
}
</script>
